import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchNHRs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/hire-request", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchMyNHRs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/my-hire-request", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchNHR(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/hire-request/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addNhr(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/hire-request", userData)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        editNhr(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/hire-request/${userData.id}`,userData.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateNHRStatus(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `/hire-request/statusUpdate/${userData.nhrData.hashid}`,
                        {
                            status: userData.nhrData.status
                        }
                    )
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        addRecruiter(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `hire-request/${userData.nhrId}/assign-to-bba/${userData.recruiter}`
                    )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        fetchCandidates(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/candidates")
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => reject(error));
            });
        },
        getCustomFields(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get("hire-request/create")
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => reject(error));
            });
        },
        addCandidates(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`hire-request/${userData.nhrId}/assign-candidates`, {
                        candidates: userData.candidates
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        closeNHR(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`hire-request/${userData.nhrId}/assign-employees`, {
                        employees: userData.employees,
                        status: userData.status
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeNHR(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/hire-request/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleNHR(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/deleteMultipleHireRequests/", { items: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
};
